import React from 'react'

const Accordianbox = () => {
    return (
        <div>
            <div className="w-11/12 max-w-5xl mx-auto py-28">
                <h1
                    style={{
                        color: "#000000",
                        maxWidth: "1000px",
                        margin: "auto",
                        fontFamily: "DM Sans",
                    }}
                    className="pb-12 text-center sm:text-4xl text-3xl font-bold  leading-normal"
                >
                    Frequently Asked Questions (FAQ)
                </h1>
                <div className="divide-y divide-gray-100 flex flex-col gap-2">
                    <details className="group shadow-lg" open="">
                        <summary className="flex cursor-pointer list-none items-center justify-between p-4 sm:text-lg text-base font-medium text-secondary-900 group-open:text-primary-500">
                        How long does it take to download a transcript?
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="block h-5 w-5 group-open:hidden"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="hidden h-5 w-5 group-open:block"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 12h-15"
                                    />
                                </svg>
                            </div>
                        </summary>
                        <div className="p-4 text-secondary-500 text-start">
                        The download time for videos transcripts depends on various factors: internet speed, the length of the transcript, and the size or length of the video files. Generally, it should take about 5-10 seconds to get download the video transcript. If the video is longer (5 minutes) it can take around 30 seconds til it’s complete. Please note that these are approximate times and may vary based on the specific circumstances mentioned above.
                        </div>
                    </details>
                    <details className="group shadow-lg">
                        <summary className="flex cursor-pointer list-none items-center justify-between p-4 sm:text-lg text-base  font-medium text-secondary-900 group-open:text-primary-500">
                        How do I download TikTok Video Transcript?
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="block h-5 w-5 group-open:hidden"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="hidden h-5 w-5 group-open:block"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 12h-15"
                                    />
                                </svg>
                            </div>
                        </summary>
                        <div className="p-4 text-secondary-500 text-start">
                        We make it easy to download your TikTok Video Transcript instantly and with as little effort as possible. To download your TikTok video Transcript all you need is the link to the video you wish to get the transcript from. Go to the TikTok video you wish to download, Copy the link to the video, and paste it into the input field up above. This will allow you to download the TikTok video transcript instantly! You can grab this like by going to the “share” icon, and clicking “Copy Link”. Now just paste it on our site and that’s it!
                        </div>
                    </details>
                    <details className="group shadow-lg">
                        <summary className="flex cursor-pointer list-none items-center justify-between p-4 sm:text-lg text-base  font-medium text-secondary-900 group-open:text-primary-500">
                        How do I download Instagram Reels Video Transcript?
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="block h-5 w-5 group-open:hidden"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="hidden h-5 w-5 group-open:block"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 12h-15"
                                    />
                                </svg>
                            </div>
                        </summary>
                        <div className="p-4 text-secondary-500 text-start">
                        In order to download your Instagram Reels video Transcript all you need is the link to the video. Go to the Instagram Reels video you wish to download, Copy the link to the video, and paste it into the input field on our website. This will allow you to download the Instagram Reels video transcript instantly! You can grab this like by going to the “share” icon, and clicking “Copy Link”. Now just paste it on our site and that’s it!
                        </div>
                    </details>

                    <details className="group shadow-lg">
                        <summary className="flex cursor-pointer list-none items-center justify-between p-4 sm:text-lg text-base  font-medium text-secondary-900 group-open:text-primary-500">
                        How do I download Youtube Shorts video transcript?
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="block h-5 w-5 group-open:hidden"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="hidden h-5 w-5 group-open:block"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 12h-15"
                                    />
                                </svg>
                            </div>
                        </summary>
                        <div className="p-4 text-secondary-500 text-start">
                        It’s simple! All you need to do is go to the Youtube Shorts video you wish to download, Copy the link to the video, and paste it into the input field on our website. This will allow you to download the Youtube Shorts video transcript instantly! You can grab this like by going to the “share” icon, and clicking “Copy Link”. Now just paste it on our site and that’s it!
                        </div>
                    </details>

                    <details className="group shadow-lg">
                        <summary className="flex cursor-pointer list-none items-center justify-between p-4 sm:text-lg text-base  font-medium text-secondary-900 group-open:text-primary-500">
                        It says there’s no transcripts to download, how come?
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="block h-5 w-5 group-open:hidden"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="hidden h-5 w-5 group-open:block"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 12h-15"
                                    />
                                </svg>
                            </div>
                        </summary>
                        <div className="p-4 text-secondary-500 text-start">
                        If you get this error it means that the video you tried to get a transcription from doesn’t exist. This has nothing to do with our website and moreso to do with the specific video you’re attempting to download. Double-check and make sure that something is being said in the video in order for us to extract those words and download them for you!
                        </div>
                    </details>

                    <details className="group shadow-lg">
                        <summary className="flex cursor-pointer list-none items-center justify-between p-4 sm:text-lg text-base  font-medium text-secondary-900 group-open:text-primary-500">
                        Will it download ANY transcript from ANY video?
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="block h-5 w-5 group-open:hidden"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="hidden h-5 w-5 group-open:block"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 12h-15"
                                    />
                                </svg>
                            </div>
                        </summary>
                        <div className="p-4 text-secondary-500 text-start">
                        We can download ANY transcript from ANY video for TikTok, Youtube Shorts, and IG Reels so long as there’s a transcript available to download. If your video has no “talking” in it, or the creator has disabled the auto-transcribe within the platform itself we will not be able to download them. Don’t worry, most videos have video transcripts enabled and this only happens on rare occasions
                        </div>
                    </details>

                    <details className="group shadow-lg">
                        <summary className="flex cursor-pointer list-none items-center justify-between p-4 sm:text-lg text-base  font-medium text-secondary-900 group-open:text-primary-500">
                        Do I need to have a TikTok, Youtube, or Instagram account to download transcripts?
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="block h-5 w-5 group-open:hidden"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="hidden h-5 w-5 group-open:block"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 12h-15"
                                    />
                                </svg>
                            </div>
                        </summary>
                        <div className="p-4 text-secondary-500 text-start">
                        Absolutely not! You don't need a TikTok, Instagram, or Youtube account to download you video transcripts. All you require is the link to the video you want to download the transcript from. Simply paste your link into the input field at the top of this page and hit "Start." and we will handle the rest!
                        </div>
                    </details>

                    <details className="group shadow-lg">
                        <summary className="flex cursor-pointer list-none items-center justify-between p-4 sm:text-lg text-base  font-medium text-secondary-900 group-open:text-primary-500">
                        Do I need to be logged into a TikTok, Youtube, or Instagram to download Transcripts?
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="block h-5 w-5 group-open:hidden"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="hidden h-5 w-5 group-open:block"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 12h-15"
                                    />
                                </svg>
                            </div>
                        </summary>
                        <div className="p-4 text-secondary-500 text-start">
                        No. You do not need to be logged into TikTok, Instagram, or Youtube when downloading the transcripts. Making it fast and easy for you to use regardless where you’re at or if you’re logged in.
                        </div>
                    </details>

                    <details className="group shadow-lg">
                        <summary className="flex cursor-pointer list-none items-center justify-between p-4 sm:text-lg text-base  font-medium text-secondary-900 group-open:text-primary-500">
                        Do I need to install extensions to use the downloader?
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="block h-5 w-5 group-open:hidden"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="hidden h-5 w-5 group-open:block"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 12h-15"
                                    />
                                </svg>
                            </div>
                        </summary>
                        <div className="p-4 text-secondary-500 text-start">
                        No. To save your video transcripts all you need to do is add the link to your TikTok, Youtube Shorts, or Instagram Reels video. Just paste it into the input field above and download your video transcript!
                        </div>
                    </details>

                    <details className="group shadow-lg">
                        <summary className="flex cursor-pointer list-none items-center justify-between p-4 sm:text-lg text-base  font-medium text-secondary-900 group-open:text-primary-500">
                        How do I get the link to download TikTok, Instagram Reels, or Youtube Shorts transcripts?
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="block h-5 w-5 group-open:hidden"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="hidden h-5 w-5 group-open:block"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 12h-15"
                                    />
                                </svg>
                            </div>
                        </summary>
                        <div className="p-4 text-secondary-500 text-start">
                        To download Youtube Shorts, TikTok Videos, and IG Reels is simple! All you need is the link to the video (available on both desktop and mobile). Copy paste it into our site and we’ll take care of the rest! It’s that simple to download any video transcript with ease!
                        </div>
                    </details>

                    <details className="group shadow-lg">
                        <summary className="flex cursor-pointer list-none items-center justify-between p-4 sm:text-lg text-base  font-medium text-secondary-900 group-open:text-primary-500">
                        Do I have to pay to download transcripts?
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="block h-5 w-5 group-open:hidden"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="hidden h-5 w-5 group-open:block"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 12h-15"
                                    />
                                </svg>
                            </div>
                        </summary>
                        <div className="p-4 text-secondary-500 text-start">
                        This website is 100% free for you to use. If you like this tool we suggest checking out our other tools and extensions for even faster social media growth and management.We support and are compatible with all browsers such as Google Chrome, Arc, Mozilla Firefox, Microsoft Edge, Safari, and more!
                        </div>
                    </details>

                    <details className="group shadow-lg">
                        <summary className="flex cursor-pointer list-none items-center justify-between p-4 sm:text-lg text-base  font-medium text-secondary-900 group-open:text-primary-500">
                        Is it free?
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="block h-5 w-5 group-open:hidden"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="hidden h-5 w-5 group-open:block"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 12h-15"
                                    />
                                </svg>
                            </div>
                        </summary>
                        <div className="p-4 text-secondary-500 text-start">
                        Yes! TikTok, Instagram Reels, and YouTube Shorts offer free video transcripts. These transcripts are created using automated speech recognition technology, so they might not be entirely accurate but are generally suitable for most purposes.
                        </div>
                    </details>
                </div>
            </div>

        </div>
    )
}

export default Accordianbox

