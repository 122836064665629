import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { CircleLoader } from "react-spinners";
import LoadingPopup from "./LoadingPopup";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = ({ isLoading }) => {
  if (isLoading) {
    return <CircleLoader color={"#e7004d"} css={override} />;
  }
  return null;
};

const Card = ({ data, loading, videoURL }) => {
  const [copied, setCopied] = useState(false);
  console.log("🚀 ~ Card ~ videoURL:", videoURL);
  console.log("🚀 ~ datacard>>>>>>>>>>>>>>>>>>>>>>>>>>>>>:", data);
  const [error, setError] = useState(null);
  const [loadingCc, setLoadingCc] = useState(false);
  const [ccData, setCcData] = useState(false);
  // console.log("🚀 ~ Card ~ ccData:", ccData?.items[0]?.snippet?.thumbnails?.standard?.url);
  console.log("🚀 ~ Card ~ ccData:", ccData?.items);

  const [hideTimestamps, setHideTimestamps] = useState(false);

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  // Example usage:
  const startTime = 4.337;
  const formattedStartTime = formatTime(startTime);
  console.log(formattedStartTime);

  function formatSubtitles(subtitlesData) {
    if (!subtitlesData) return null;

    return subtitlesData.map((line, index) => {
      let formattedTimeString = formatTime(line?.start);

      if (hideTimestamps) {
        return (
          <div
            className="text-white line-content w-full bg-gray-800 hover:bg-gray-900 rounded  py-1 transition-colors duration-200 flex mb-4 px-1 items-center"
            key={index}
          >
            <span
              //  style={{ color: "white" }}
              className="text hover:text-gray-200 text-xs text-justify"
            >
              {line?.text}
            </span>
          </div>
        );
      } else {
        return (
          <div
            className="text-white line-content w-full bg-gray-800 hover:bg-gray-900 rounded  py-1 transition-colors duration-200 flex mb-4 px-1 items-center"
            key={index}
          >
            <span
              style={{
                color: "rgb(227, 39, 102)",
                fontWeight: "700",
              }}
              className="timestamp bg-gray-700 text-rose-500 hover:text-rose-500 rounded px-1 mr-2 text-xs w-11 h-fit"
            >
              {/* {line?.start} */}
              {formattedTimeString}
            </span>
            <span
              //  style={{ color: "white" }}
              className="text hover:text-gray-200 text-xs text-justify"
            >
              {line?.text}
            </span>
          </div>
        );
      }
    });
  }

  const formattedHTML = formatSubtitles(data?.transcript);
  console.log("formattedHTML>>>>>>>>>>>>.", formattedHTML);

  useEffect(() => {
    if (data && data.video_id) {
      setLoadingCc(true);
      fetch(
        `https://yt.lemnoslife.com/noKey/videos?part=snippet&id=${data.video_id}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json(); // Parse response body as JSON
        })
        .then((jsonData) => {
          // Handle JSON data
          console.log("JSON data:", jsonData);
          // Set ccData state with parsed JSON data
          setCcData(jsonData);
          setLoadingCc(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoadingCc(false);
          setError(error.message);
        });
    }
  }, [data]);

  const copyCC = () => {
    const transcriptText = data?.transcript.map((line) => line.text).join("\n");
    navigator.clipboard
      .writeText(transcriptText)
      .then(() => {
        setCopied(true);
        console.log("Caption text copied successfully");
        setTimeout(() => setCopied(false), 1000);
      })
      .catch((error) => console.error("Error copying transcript text:", error));
  };

  return (
    <div className="sm:w-11/12 m-auto animate__animated animate__fadeInDown">
      <div
        className="rounded-md md:min-h-[700px] sm:min-h-[500px] md:min-w-[700px] sm:min-w-[500px] min-w-[300px] md:flex sm:mx-auto mx-2 bg-white lg:py-11 lg:px-9 sm:py-9 sm:px-6 p-3 shadow-lg"
        style={{ maxHeight: "700px", maxWidth: "920px" }}
      >
        <div
          className="w-2/5 flex-none text-center overflow-hidden hidden md:flex hover:p-1"
          title=""
        >
          {!ccData ? (
            <Loader isLoading={loadingCc} />
          ) : (
            <img
              crossorigin="anonymous"
              src={`https://instagramserver.tokbackup.com/proxy?media_url=${ccData?.items[0]?.snippet?.thumbnails?.standard?.url}`}
              alt="Thumbnail"
              className="rounded-lg object-cover"
              style={{ maxHeight: "650px" }}
            />
          )}
        </div>
        <div
          className="rounded-md p-2 flex flex-col leading-normal md:ml-3 md:w-3/5 md:min-h-0 sm:min-h-[430px] min-h-[300px] overflow-y-auto"
          style={{
            backgroundColor: "rgba(31,41,55,255)",
            maxHeight: "600px",
            padding: "10px",
          }}
        >
          <div className="flex justify-between items-start">
            <div
              className=" switch bg-gray-700 rounded px-2 py-1 text-xs text-white flex items-center"
              onClick={() => setHideTimestamps(!hideTimestamps)}
            >
              <input
                type="checkbox"
                className="mr-1"
                checked={hideTimestamps}
                readOnly
              />
              <label className="">Hide Timestamps</label>
            </div>
            <div>
              <button
                className="inline-block px-3 py-2.5 bg-pink-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md 
                hover:bg-pink-700 hover:shadow-lg focus:bg-pink-700 focus:shadow-lg focus:outline-none focus:ring-0 
                active:bg-pink-800 active:shadow-lg transition duration-150 ease-in-out
                bg-gradient-to-r from-[#e7004d]  to-[#a205c3] whitespace-nowrap w-24
 "
                onClick={() => copyCC(data?.transcript)}
              >
                {copied ? "Copied!" : "Copy"}
              </button>
            </div>
          </div>
          <div className="sm:px-8 py-6 px-2">
            {loadingCc ? (
              <div className="loader-container">
                <LoadingPopup />
              </div>
            ) : // ) : error ? (
            // <div>Error: {error}</div>

            data && data.transcript ? (
              formatSubtitles(data?.transcript)
            ) : (
              <p style={{ color: "white" }}>Subtitles Not Available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
