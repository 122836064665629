import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <footer className="text-gray-700 pb-2 pt-6 border-t" style={{ backgroundColor: "rgb(245, 245, 245)" }}>
            <div className="mx-auto max-w-5xl px-6 py-10 md:px-12 ">
                <div className="flex flex-wrap -mx-6 gap-y-12" >
                    <div className="w-full md:w-1/2 lg:w-1/4 px-6 mb-6 md:mb-0">
                        <div className="flex lg:items-baseline items-center flex-col">
                        <img src="Images\logo.png" className="logo mb-4" alt="TokTools logo" />
                        <a href="mailto:support@toktools.com" className="block mb-4" style={{textDecoration:"none"}}>
                            <button className="email-btn text-gray-700 ">
                                Email Us
                            </button>
                        </a>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/4 px-6 mb-6 lg:mb-0">
                        <h1 className="text-xl font-bold mb-4 lg:text-left text-center">
                            Other Tools
                        </h1>
                        <ul className="text-sm pl-0">
                            <li className="mb-2 lg:text-left text-center">
                                <a href="https://www.tokbackup.com/" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                    TokBackup
                                </a>
                            </li>
                            <li className="mb-2 lg:text-left text-center">
                                <a href="https://www.tokaudit.io/" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                    TokAudit
                                </a>
                            </li>
                            <li className="mb-2 lg:text-left text-center">
                                <a href="https://chromewebstore.google.com/detail/tiktok-ads-video-download/defjbhlehbkebaifofnaalipneaeaick" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                    TokView
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/4 px-6">
                        <h1 className="text-xl font-bold mb-4 lg:text-left text-center">
                            Resources
                        </h1>
                        <ul className="text-sm pl-0">
                            <li className="mb-2 lg:text-left text-center">
                                <a href="https://ttcomments.tokbackup.com/" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                    TokAnalyze
                                </a>
                            </li>
                            <li className="mb-2 lg:text-left text-center">
                                <a href="https://script.tokaudit.io/" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                    TokScript
                                </a>
                            </li>
                            <li className="mb-2 lg:text-left text-center">
                                <a href="https://talkthetok.com/" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                    TikTok Podcast
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/4 px-6">
                        <h1 className="text-xl font-bold mb-4 lg:text-left text-center">
                            Helpful Links
                        </h1>
                        <ul className="text-sm pl-0" >
                        <li className="mb-2 lg:text-left text-center">
                                <a href="https://tokdownload.com" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                TokDownload
                                </a>
                            </li>
                            <li className="mb-2 lg:text-left text-center">
                                <a href="https://chat.openai.com/g/g-9BGRz0SfJ-viral-intro-hooks" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                    HooksGPT
                                </a>
                            </li>
                            <li className="mb-2 lg:text-left text-center">
                                <a href="https://chat.openai.com/g/g-0y72K7Njo-viral-ads-script" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                    ViralAdsGPT
                                </a>
                            </li>
                         

                        </ul>
                    </div>
                </div>

            

            </div>
            <p 
            //className="text-sm"
            className="text-center text-sm text-gray-500 mt-10"
            >
                            TOKcomment.com © {new Date().getFullYear()} - All rights reserved
                        </p>
        </footer>
  );
};

export default Footer;
