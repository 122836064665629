import React from 'react'

const AboutSection = () => {
    return (
        <div>
            <div className='bg-white  z-20  '>
                <div className="mx-auto w-11/12 max-w-5xl   border-b pb-28 pt-8" style={{ width: '90%', maxWidth: '1000px' }}>
                    <h1 className="pb-12 text-center sm:text-4xl text-3xl font-bold  leading-normal">About TokScript Free Video Transcript Generator</h1>

                    <p className="mb-8 text-sm-xl text-left text-gray-800 leading-relaxed">
                        Download Video Transcripts (captions) For Free. Instantly, without uploading any files! Quick and simple. No catch.
                    </p>

                    <p className="mb-8 text-sm-xl text-left text-gray-800 leading-relaxed">
                    Download any video’s captions, transcripts, and words for your TikTok, Instagram Reels, and Youtube Shorts videos in seconds. Add your video link, hit start, and instantly get any TikTok, Reels, or Shrots video script within seconds. Perfect for UGC creators, Media Buyers, Ads Experts, Creators, and Influencers who need help coming up with ideas or understanding what videos 
                    </p>

                    <p className="mb-8 text-sm-xl text-left text-gray-800 leading-relaxed">
                    Easily and instantly download any video transcripts from your favorite TikTok, Youtube Shorts, and Instagram Reels videos instantly!
                    </p>

                    <div className="space-y-6">
                        <p className="text-sm-xl text-left text-gray-800 leading-relaxed">
                            <strong className="font-bold text-xl">TikTok Ads:</strong> <br/>If you’re struggling to come up with ideas or need to know what a video is talking about you can easily download any videos transcript / subtitles to easily come up with new ideas or use for SEO and topic creation. 
                        </p>

                        <p className="text-sm-xl text-left text-gray-800 leading-relaxed">
                            <strong className="font-bold text-xl">UGC Creators:</strong><br/>If you’re struggling with what to say in your videos or just want a quick reminder of what does work and doesn’t work, you can now download any transcript subtitles and reuse them for any future video ideas you might have.
                        </p>

                        <p className="text-sm-xl text-left text-gray-800 leading-relaxed">
                            <strong className="font-bold text-xl">AI:</strong> <br/>Easily download any TikTok, Reels, or Shorts video’s video transcript and subtitles so you can use it with any of your favorite AI companies like ChatGPT, Bard, Claude, and more to easily create User Generated Video Scripts and ideas for your next video!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection
