import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { CircleLoader } from "react-spinners";
import LoadingPopup from "./LoadingPopup";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = ({ isLoading }) => {
  if (isLoading) {
    return <CircleLoader color={"#e7004d"} css={override} />;
  }
  return null;
};

const Card = ({ data, loading }) => {
  const [copied, setCopied] = useState(false);
  console.log("🚀 ~ datacard>>>>>>>>>>>>>>>>>>>>>>>>>>>>>:", data);
  const [error, setError] = useState(null);
  const [loadingCc, setLoadingCc] = useState(false);
  const [ccData, setCcData] = useState(false);
  console.log("🚀 ~ Card ~ ccData:", ccData?.url);

  const [hideTimestamps, setHideTimestamps] = useState(false); // State to track whether timestamps should be hidden

  function formatSubtitles(subtitlesData) {
    if (!subtitlesData) return null;

    const subtitleLines = subtitlesData.trim().split(/\n\n+/);
    if (!subtitleLines) return null;

    return subtitleLines.map((line, index) => {
      const [timestamp, text] = line.split("\n").slice(1);
      console.log("🚀 ~ retur0]:", timestamp.split(" --> ")[0].slice(3, -4));
      const formattedTime = timestamp.split(" --> ")[0].slice(3, -4);

      if (hideTimestamps) {
        // Check if timestamps should be hidden
        return (
          <div
            className="text-white line-content w-full bg-gray-800 hover:bg-gray-900 rounded  py-1 transition-colors duration-200 flex mb-4 px-1 items-center"
            key={index}
          >
            <span
              //  style={{ color: "white" }}
              className="text hover:text-gray-200 text-xs text-justify"
            >
              {text}
            </span>
          </div>
        );
      } else {
        // let parts = formattedTime.split(":");
        // let secondsAndMilliseconds = parts[1].split(",");
        // let seconds = parseInt(secondsAndMilliseconds[0]);
        // let milliseconds = parseInt(secondsAndMilliseconds[1]);
        // let formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
        // let formattedTimeString = `${formattedSeconds}:${milliseconds}`;

        return (
          <div
            className="text-white line-content w-full bg-gray-800 hover:bg-gray-900 rounded  py-1 transition-colors duration-200 flex mb-4 px-1 items-center"
            key={index}
          >
            <span
              style={{
                color: "rgb(227, 39, 102)",
                fontWeight: "700",
              }}
              className="timestamp bg-gray-700 text-rose-500 hover:text-rose-500 rounded px-1 mr-2 text-xs w-11 h-fit"
            >
              {formattedTime}
            </span>
            <span
              //  style={{ color: "white" }}
              className="text hover:text-gray-200 text-xs text-justify"
            >
              {text}
            </span>
          </div>
        );
      }
    });
  }

  const formattedHTML = formatSubtitles(data?.subtitles_text);
  console.log("formattedHTML>>>>>>>>>>>>.", formattedHTML);

  useEffect(() => {
    if (data && data.thumbnail_url) {
      // Fetch API data
      setLoadingCc(true);
      fetch(
        "https://instagramserver.tokbackup.com/proxy?media_url=" +
          encodeURIComponent(data.thumbnail_url)
      )
        .then((response) => {
          console.log("🚀 ~ useEffect ~ response:", response);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          setCcData(response);
          return response.json();
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoadingCc(false);
          setError(error.message);
        });
    }
  }, [data]);

  // const copyCC = (text) => {
  //   navigator.clipboard
  //     .writeText(text)
  //     .then(() => console.log("Closed caption text copied successfully"))
  //     .catch((error) =>
  //       console.error("Error copying closed caption text:", error)
  //     );
  // };

  let subtitlesWithoutHeader;
  const copyCC = (textProps) => {
    const extractTextFromProps = (text) => {
      subtitlesWithoutHeader = text?.slice(text.indexOf("\n") + 1);
      const timestampRegex =
        /\d{2}:\d{2}:\d{2}.\d+ --> \d{2}:\d{2}:\d{2}.\d+\n/g;
      const cleanedText = subtitlesWithoutHeader.replace(timestampRegex, "");
      return cleanedText.trim();
    };

    const extractTextFromProps2 = (text) => {
      subtitlesWithoutHeader = text?.slice(text.indexOf("\n") + 1);
      const cleanedText = subtitlesWithoutHeader;
      return cleanedText.trim();
    };
    const text = extractTextFromProps(textProps);
    const text2 = extractTextFromProps2(textProps);
    console.log("🚀 ~ copyCC ~ text:", text);
    if (!navigator.clipboard) {
      console.error("Clipboard API not supported");
      return;
    }

    navigator.clipboard
      .writeText(hideTimestamps == true ? text : text2)
      .then(() => {
        setCopied(true);
        console.log("Closed caption text copied successfully");
        setTimeout(() => setCopied(false), 1000);
      })
      .catch((error) =>
        console.error("Error copying closed caption text:", error)
      );
  };

  return (
    <div className="sm:w-11/12 m-auto animate__animated animate__fadeInDown">
      {/* Add Copy CC button */}
      {/* <button
        onClick={() => copyCC(data?.subtitles_text)}
        className="ml-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
      >
        Copy CC
      </button> */}

      <div
        className="rounded-md md:min-h-[700px] sm:min-h-[500px] md:min-w-[700px] sm:min-w-[500px] min-w-[300px] md:flex sm:mx-auto mx-2 bg-white lg:py-11 lg:px-9 sm:py-9 sm:px-6 p-3 shadow-lg"
        style={{ maxHeight: "700px", maxWidth: "920px" }}
      >
        <div
          className="w-2/5 flex-none text-center overflow-hidden hidden md:flex hover:p-1"
          title=""
        >
          {!ccData?.url ? (
            <Loader isLoading={loadingCc} />
          ) : (
            <img
              crossorigin="anonymous"
              src={ccData?.url}
              alt="Thumbnail"
              className="rounded-lg object-cover"
              style={{ maxHeight: "650px" }}
            />
          )}
        </div>
        <div
          className="rounded-md p-2 flex flex-col leading-normal md:ml-3 md:w-3/5 md:min-h-0 sm:min-h-[430px] min-h-[300px] overflow-y-auto"
          style={{
            backgroundColor: "rgba(31,41,55,255)",
            maxHeight: "600px",
            padding: "10px",
          }}
        >
          <div className="flex justify-between items-start">
            <div
              className=" switch bg-gray-700 rounded px-2 py-1 text-xs text-white flex items-center"
              onClick={() => setHideTimestamps(!hideTimestamps)} // Toggle hideTimestamps state on button click
            >
              <input
                type="checkbox"
                className="mr-1"
                checked={hideTimestamps}
                readOnly
              />
              <label className="">Hide Timestamps</label>
            </div>
            <div>
              <button
                className="inline-block px-3 py-2.5 bg-pink-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md 
                hover:bg-pink-700 hover:shadow-lg focus:bg-pink-700 focus:shadow-lg focus:outline-none focus:ring-0 
                active:bg-pink-800 active:shadow-lg transition duration-150 ease-in-out
                  bg-gradient-to-r from-[#e7004d]  to-[#a205c3] whitespace-nowrap w-24"
                onClick={() => copyCC(data?.subtitles_text)}
              >
                {copied ? "Copied!" : "Copy"}
              </button>
            </div>
          </div>
          <div className="sm:px-8 py-6 px-2">
            {loadingCc ? (
              <div className="loader-container">
                <LoadingPopup />
              </div>
            ) : // ) : error ? (
            // <div>Error: {error}</div>

            data && data.subtitles_text ? (
              formatSubtitles(data?.subtitles_text)
            ) : (
              <p style={{ color: "white" }}>{data?.video_subtitles_uri}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
