import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const Topbar = () => {
  // Add state for controlling dropdown visibility
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const apicall = () => {
    const emailInput = document.getElementById("newsletterInput");
    const email = emailInput.value.trim();

    if (email !== "") {
      axios
        .post("http://localhost:6066/tc/api/subscription/", { email })
        .then((response) => {
          console.log("response>>>>>>",response.email);
          emailInput.value = "";
              toast.success("Subscription successful!");
        })
        .catch((error) => {
          console.error(error);
          toast.error("Subscription failed. Please try again.");
        });
    } else {
      toast.error("Please enter a valid email address.");
    }
  };

  // Function to close dropdown
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  return (
    <div className="sticky z-50 " style={{ top: "0px" }}>
      <nav className="" style={{ backgroundColor: "transparent" }}>
        <div className="" style={{ backgroundColor: "rgb(245, 245, 245)" }}>
          <div
            className=" relative flex h-20 items-center justify-between px-3 mx-auto max-w-7xl"    //shadow-md shadow-gray-300
            
          >
            {/* Toggle Show in lg */}
            

            <div className="flex flex-1 items-center justify-start">
              {/* Logo */}
              <div className="flex  items-center">
                <img
                  className="sm:h-10 h-9"
                  src="Images\logo.png"
                  alt="Your Company"
                />
              </div>
              {/* Links */}
              <div className="hidden sm:ml-auto lg:block">
                <div className="flex space-x-4 font-medium">
                  {/* <a className="text-neutral-400 font-normal  rounded-md  xl:px-2 py-2 lg:px-0 text-sm">
                    Get Exclusive Updates! Subscribe Now
                  </a> */}
                   <a
                                        href="https://www.tokaudit.io/" 
                                        target="_blank"
                                        className="text-black  hover:bg-black hover:text-white rounded-md xl:px-2 py-2 lg:px-0 text-sm "
                                    >
                                        TokAudit
                                    </a>
                                    <a
                                        href="https://www.tokbackup.com/"
                                        target="_blank"
                                        className="text-black  hover:bg-black hover:text-white rounded-md xl:px-2 py-2 lg:px-1 text-sm"
                                    >
                                        TokBackup
                                    </a>
                                    <a
                                        href="https://tokdownload.com/"
                                        target="_blank"
                                        className="text-black hover:bg-black hover:text-white rounded-md xl:px-2 py-2 lg:px-1 text-sm"
                                    >
                                        TokDownload
                                    </a>
                                    <a
                                        href="https://tokcomment.com/"
                                        target="_blank"
                                        className="text-black hover:bg-black hover:text-white rounded-md xl:px-2 py-2 lg:px-1 text-sm"
                                    >
                                        TokComment
                                    </a>
                                    <a
                                        href="https://talkthetok.com/"
                                        target="_blank"
                                        className="text-black hover:bg-black hover:text-white rounded-md xl:px-2 py-2 lg:px-1 text-sm"
                                    >
                                        TikTok Podcast
                                    </a>
                </div>
              </div>
            </div>

            {/* Button */}
            {/* <div className="  flex  items-center "> */}
              {/* <a
                                href="https://chromewebstore.google.com/detail/tokaudit-tiktok-sorting-a/cijmoklipjlcmdipoacmehggpggoaman"
                                target='_blank'
                                className=" text-white rounded-md px-2 py-2  sm:px-3 sm:py-3 sm:text-sm text-xs font-medium"
                                aria-current="page"
                                style={{ border: "2px solid rgb(213, 209, 209)", background: "linear-gradient(90deg, rgb(0, 0, 0) 10%, rgb(38, 1, 52) 70%)" }}
                            >
                                Get Extention
                            </a> */}

              {/* <form class="w-full max-w-sm">
                <div class="flex items-center border border-black p-0 rounded-lg">
                  <input
                    id="newsletterInput"
                    className="appearance-none bg-transparent rounded-l-lg  w-full text-gray-700 p-2 leading-tight focus:outline-none"
                    type="text"
                    placeholder="Newsletter"
                    aria-label="Full name"
                  />
                  <button
                    onClick={apicall}
                    className="flex-shrink-0 bg-black text-white p-2 rounded-r-lg"
                    type="button"
                  >
                    Subscribe
                  </button>

                 <input class="appearance-none bg-transparent rounded-l-lg  w-full text-gray-700  p-2 leading-tight focus:outline-none " type="text" placeholder="Newsletter" aria-label="Full name" />
                                    <button class="flex-shrink-0 bg-black text-white p-2 rounded-r-lg" type="button">
                                    Subscribe
                                    </button> 
                </div>
              </form> */}

              {/* <input type="text" placeholder='Subscription' className='p-2 rounded-l-lg border border-black' /> <button type="button" className='bg-black text-white p-2 rounded-r-lg'>Subscription</button> */}
            {/* </div> */}

            <div className="relative lg:hidden inline-block text-left">
              <button
                type="button"
                onClick={toggleDropdown}
                className="inline-flex w-full justify-center gap-x-1.5 rounded-lg bg-black px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset  hover:bg-gray-50"
                id="menu-button"
                aria-expanded={isDropdownOpen ? "true" : "false"}
                aria-haspopup="true"
              >
                <img
                  className="sm:w-6 w-5"
                  src="Images\toggle.png"
                  alt="Your Company"
                />
              </button>

              {/* Dropdown menu */}
              {isDropdownOpen && (
                                <div
                                    className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none animate__animated animate__fadeInUp"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="menu-button"
                                    tabIndex={-1}
                                >
                                    <a
                                        href="https://toktools.com/"
                                        target="_blank"
                                        className="text-black font-normal hover:bg-black hover:text-white hover:font-medium rounded-sm px-3 py-2 text-sm block"
                                        role="menuitem"
                                        tabIndex={-1}
                                        id="menu-item-0"
                                        onClick={closeDropdown}
                                    >
                                        TokTools
                                    </a>
                                    <a
                                        href="https://www.tokaudit.io/" 
                                        target="_blank"
                                        className="text-black font-normal hover:bg-black hover:text-white hover:font-medium rounded-sm px-3 py-2 text-sm block"
                                        role="menuitem"
                                        tabIndex={-1}
                                        id="menu-item-0"
                                        onClick={closeDropdown}
                                    >
                                        TokAudit
                                    </a>
                                    <a
                                        href="https://www.tokbackup.com/"
                                        target="_blank"
                                        className="text-black font-normal hover:bg-black hover:text-white hover:font-medium rounded-sm px-3 py-2 text-sm block"
                                        role="menuitem"
                                        tabIndex={-1}
                                        id="menu-item-0"
                                        onClick={closeDropdown}
                                    >
                                        TokBackup
                                    </a>
                                    <a
                                        href="https://tokdownload.com/"
                                        target="_blank"
                                        className="text-black font-normal hover:bg-black hover:text-white hover:font-medium rounded-sm px-3 py-2 text-sm block"
                                        role="menuitem"
                                        tabIndex={-1}
                                        id="menu-item-0"
                                        onClick={closeDropdown}
                                    >
                                        TokDownload
                                    </a>
                                    <a
                                        href="https://tokcomment.com/"
                                        target="_blank"
                                        className="text-black font-normal hover:bg-black hover:text-white hover:font-medium rounded-sm px-3 py-2 text-sm block"
                                        role="menuitem"
                                        tabIndex={-1}
                                        id="menu-item-0"
                                        onClick={closeDropdown}
                                    >
                                        TokComment
                                    </a>
                                    <a
                                        href="https://talkthetok.com/"
                                        target="_blank"
                                        className="text-black font-normal hover:bg-black hover:text-white hover:font-medium rounded-sm px-3 py-2 text-sm block"
                                        role="menuitem"
                                        tabIndex={-1}
                                        id="menu-item-0"
                                        onClick={closeDropdown}
                                    >
                                        TikTok Podcast
                                    </a>
                                </div>
                            )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Topbar;
